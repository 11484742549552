import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import Img from 'gatsby-image'
import {
  Row,
  Col,
  Gap,
  Text,
  Title,
  Section,
  Button,
  TechnologyTop,
  TechnologyTopVideo,
  Link,
  SEO,
} from '../components'
import { up, addResponsivity } from '../lib/styles'
import RotorAnimation from '../components/RotorAnimation'

const CockpitWrapper = styled.div`
  position: relative;
  width: 100%;
`
// const SkyWrapper = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   overflow: hidden;
//   ${addResponsivity()}
// `

const StyledSkyWrapper = styled.div`
  ${({ theme: { colors }, height }) => css`
    position: relative;
    width: 100%;
    overflow: hidden;
    background: white;

    height: ${height}px;
  `}
`

const SkyWrapper = ({ zuriSkyImage }) => {
  const isBrowser = typeof window !== 'undefined'
  const [windowWidth, setWindowWidth] = useState(
    isBrowser ? window.innerWidth : 0,
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const imageWidth = windowWidth > 1920 ? 1920 : windowWidth
  const wrapperHeight = imageWidth * 0.42

  return (
    <StyledSkyWrapper height={wrapperHeight}>
      <Parallax y={[-30, 0]}>
        <Img fluid={zuriSkyImage.image.fluid} />
      </Parallax>
    </StyledSkyWrapper>
  )
}

const HighlightImageCol = (props) => (
  <Col
    grow="1"
    maxWidth="740px"
    mobileMaxWidth="600px"
    bp="tablet"
    shrink="1"
    basis="50%"
    {...props}
  />
)
const HighlightTextCol = (props) => (
  <Col maxWidth="680px" shrink="3" {...props} />
)

const InlineHeader = (props) => (
  <Text
    as="h2"
    black
    style={{ display: 'inline', textTransform: 'uppercase' }}
    {...props}
  />
)

class Index extends React.Component {
  rotorRef = React.createRef()

  render() {
    const {
      location: { pathname },
      data: {
        topImage,
        topTabletImage,
        t1,
        t2,
        t3,
        // cockpitImage,
        // skyImage,
        // cabinImage1,
        // cabinImage2,
        zuriSkyImage,
      } = {},
    } = this.props

    return (
      <>
        <SEO
          title="VTOL Technology & Safety Systems | Zuri"
          description="Discover Zuri’s advanced VTOL technology with tiltrotors, flight control systems, and AI-powered safety, designed for efficient air mobility."
          pathname={pathname}
        />
        <Gap.Top />
        <div style={{ width: '100%', background: 'white' }}>
          <TechnologyTopVideo />
          <Section background="white">
            <Gap gap="60px" mobileGap="60px" />
            <Title.Big innerAs="h1" uppercase>Zuri Hybrid VTOL Technology</Title.Big>
            <Gap gap="150px" mobileGap="50px" bp="tablet" />
            <Row
              responsive
              bp="tablet"
              alignItems="center"
              mobileAlignItems="unset"
            >
              <HighlightImageCol maxHeight="500px">
                <Img
                  ref={this.rotorRef}
                  fluid={t1.image.fluid}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
                <RotorAnimation rotorImageRef={this.rotorRef} />
              </HighlightImageCol>

              <Gap gap="80px" mobileGap="30px" bp="tablet" />
              <HighlightTextCol>
                <Text>
                  <InlineHeader>
                    Tiltrotor System & Electric Propulsion.{' '}
                  </InlineHeader>
                  Features four propulsion units at the front and four at the
                  back, capable of tilting between vertical and horizontal
                  flight.
                </Text>
              </HighlightTextCol>
            </Row>

            <Gap gap="140px" mobileGap="80px" showBelow="desktop" bp="tablet" />
            <Gap gap="120px" showAfter="desktop" />

            <Row
              responsive
              bp="tablet"
              alignItems="center"
              mobileAlignItems="unset"
            >
              <HighlightImageCol maxHeight="360px">
                <Img
                  fluid={t2.image.fluid}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
              </HighlightImageCol>
              <Gap gap="80px" mobileGap="40px" bp="tablet" />
              <HighlightTextCol>
                <Text>
                  <InlineHeader>
                    Hybrid Power Generation & Energy Supply.{' '}
                  </InlineHeader>
                  Onboard turbine generator supplies electric energy, enabling a
                  longer range than eVTOLs.
                </Text>
              </HighlightTextCol>
            </Row>

            <Gap gap="140px" mobileGap="80px" showBelow="desktop" bp="tablet" />
            <Gap gap="120px" showAfter="desktop" />

            <Row
              responsive
              bp="tablet"
              alignItems="center"
              mobileAlignItems="unset"
            >
              <HighlightImageCol maxHeight="457px">
                <Img
                  fluid={t3.image.fluid}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
              </HighlightImageCol>
              <Gap gap="80px" mobileGap="40px" bp="tablet" />
              <HighlightTextCol>
                <Text>
                  <InlineHeader>Battery Pack & Safety Systems. </InlineHeader>
                  Lightweight lithium cells with an intelligent battery
                  management system and fireproof insulation.
                </Text>
              </HighlightTextCol>
            </Row>
            <Gap gap="180px" mobileGap="80px" />
          </Section>
        </div>

        <SkyWrapper zuriSkyImage={zuriSkyImage} />
   
        <Section>
          <Gap gap="125px" mobileGap="40px" bp="tablet" />
          <Row justifyContent="space-between" responsive bp="tablet">
            <Col maxWidth="686px">
              <Text>
                <InlineHeader>
                  Flight Control & Autopilot Technology.{' '}
                </InlineHeader>
                Redundant fly-by-wire system and AI-driven autopilot for safer
                flight.
              </Text>
            </Col>
            <Gap gap="80px" mobileGap="40px" bp="tablet" />
            <Col maxWidth="748px">
              <Text>
                <InlineHeader>AI Sensors & Computer Vision. </InlineHeader>
                Computer vision enhances situational awareness for pilots with
                object recognition and 3D modeling.
              </Text>
            </Col>
          </Row>
          <Gap gap="125px" mobileGap="40px" bp="tablet" />
          <Row justifyContent="center">
            <Button.Link to={Link.STORY}>STORY BEHIND</Button.Link>
          </Row>
          <Gap gap="80px" mobileGap="40px" />
        </Section>
      </>
    )
  }
}

export default Index

export const pageQuery = graphql`
  fragment TechnologyImage1 on File {
    image: childImageSharp {
      fluid(maxWidth: 675, quality: 95) {
        # fluid(maxWidth: 1280, maxHeight: 618, quality: 95) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment TechnologyImage2 on File {
    image: childImageSharp {
      fluid(maxWidth: 620, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment TechnologyImage3 on File {
    image: childImageSharp {
      fluid(maxWidth: 620, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment TopMobileImage on File {
    image: childImageSharp {
      fluid(maxWidth: 980, quality: 95) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    topImage: file(relativePath: { eq: "images/technology/top.jpg" }) {
      ...FullHdImage
    }
    topTabletImage: file(
      relativePath: { eq: "images/technology/top-tablet.jpg" }
    ) {
      ...TopMobileImage
    }
    t1: file(relativePath: { eq: "images/technology/tiltrotor.2.png" }) {
      ...TechnologyImage1
    }
    t2: file(relativePath: { eq: "images/technology/gen-set.png" }) {
      ...TechnologyImage2
    }
    t3: file(relativePath: { eq: "images/technology/battery.png" }) {
      ...TechnologyImage3
    }
    zuriSkyImage: file(relativePath: { eq: "images/technology/zuri-sky.jpg" }) {
      ...FullHdImage
    }
    # cabinImage1: file(
    #   relativePath: { eq: "images/technology/zuri-cabin-1.jpg" }
    # ) {
    #   ...FullHdImage
    # }
    # cabinImage2: file(relativePath: { eq: "images/technology/zuri.jpg" }) {
    #   ...FullHdImage
    # }
    # cockpitImage: file(relativePath: { eq: "images/technology/cockpit.png" }) {
    #   ...FullHdImage
    # }
    # skyImage: file(relativePath: { eq: "images/technology/sky.jpg" }) {
    #   ...FullHdImage
    # }
  }
`
